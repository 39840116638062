<template>
  <div id='book'>
    <el-card>
      <el-row :gutter="20" class="operate">
        <el-col :span="4">
          <el-input v-model="selectName" placeholder="请输入搜索的书籍" clearable></el-input>
        </el-col>
        <el-button @click="selectList" icon="el-icon-search" type="primary"></el-button>
        <el-button @click="showDialog = true, isAddFlag = true" type="primary">添加书籍</el-button>
      </el-row>

      <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
        <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column label="封面" width="100" class-name="avatar">
          <template #default="scope"><el-avatar :size="40" :src="scope.row.cover_pic" shape="square"></el-avatar></template>
        </el-table-column>
        <el-table-column prop="book_name" label="书籍名称" width="180"></el-table-column>
        <el-table-column prop="author_name" label="作者姓名" width="180"></el-table-column>
        <el-table-column prop="cat_name" label="分类名称" width="180"></el-table-column>
        <el-table-column prop="desc" label="简介"></el-table-column>
        <el-table-column fixed="right" label="操作" width="210">
          <template #default="scope">
            <el-button @click="queryItem(scope.row.id)" type="primary" size="mini">查看</el-button>
            <el-button @click="openDialog(scope.row)" type="warning" size="mini">修改</el-button>
            <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

       <!-- 添加弹窗 -->
      <el-dialog @close="closeDialog(isAddFlag, 'addBookItem')" title="添加书籍" :visible.sync="showDialog" class="add_dialog">
        <el-form :model="form" ref="addBookItem" :rules="rules">
          <el-form-item label="封面图片" :label-width="formLabelWidth">
            <el-upload :action="baseURL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" class="avatar-uploader">
              <img v-if="form.cover_pic" :src="form.cover_pic" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="书籍名称" prop="book_name" :label-width="formLabelWidth">
            <el-input v-model="form.book_name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="作者姓名" prop="author_id" :label-width="formLabelWidth">
            <el-select v-model="form.author_id" ref="authorName" placeholder="请选择作者" filterable clearable>
              <el-option v-for="item in authorList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="分类" prop="cat_id" :label-width="formLabelWidth">
            <el-select v-model="form.cat_id" placeholder="请选择分类" filterable clearable>
              <el-option v-for="item in catList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="简介" :label-width="formLabelWidth">
            <el-input v-model="form.desc" type="textarea" :autosize="{minRows: 2, maxRows: 4}" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog(isAddFlag, 'addBookItem')">取 消</el-button>
          <el-button v-if="isAddFlag" @click="addBook('addBookItem')" type="primary">确 定</el-button>
          <el-button v-else @click="editItem('addBookItem')" type="primary">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 查看弹窗 -->
      <el-dialog title="查看作者" :visible.sync="checkItem" center class="check_dialog">
        <div class="check_div">
          <div class="check_label">封面图片：</div>
          <div @click="getSrcList(checkData.cover_pic)" class="check_data">
            <el-image :src="checkData.cover_pic" :preview-src-list="srcList"></el-image>
          </div>
        </div>
        <div class="check_div">
          <div class="check_label">书籍名称：</div>
          <div class="check_data">{{checkData.book_name}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">书籍作者：</div>
          <div class="check_data">{{checkData.author_name}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">分类名称：</div>
          <div class="check_data">{{checkData.cat_name}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">简介：</div>
          <div class="check_data">{{checkData.desc}}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkItem = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {getBookList, getAuthorAll, getCateAll, addBookItem, queryBookItem, editBookItem, deleteBookItem} from '@api';

export default {
  data () {
    return {
      params: { // 请求分页参数
        page: 1,
        page_size: 10
      },
      tableData: [], // 表格数据
      loading: true, // 表格加载等待
      showDialog: false, // 是否显示弹窗
      isAddFlag: false, // 是否点击添加用户
      form: { // 添加表单
        cover_pic: '',
        author_id: '',
        author_name: '',
        book_name: '',
        cat_id: '',
        desc: '',
        status: 0,
      },
      rules: {
        book_name: [
          {required: true, message: '请输入书籍名称', trigger: 'blur'}
        ],
        author_id: [
          {required: true, message: '请选择作者', trigger: 'blur'}
        ],
        cat_id: [
          {required: true, message: '请选择分类', trigger: 'blur'}
        ]
      },
      formLabelWidth: '120px', // from表单lanel的宽度
      total: 0, // 数据总条数
      checkItem: false, // 查看每条的弹窗
      checkData: {}, // 查询每条时的数据
      selectName: '', // 搜索姓名
      baseURL: `${this.$store.state.config.baseURL}/upload`, // 上传图片地址
      authorList: [], // 作者列表
      catList: [], // 分类列表
      srcList: [''], // 查看图片
    }
  },
  created() {
    this.getData(this.params);
    getAuthorAll().then(res => this.authorList = res.data);
    getCateAll().then(res => this.catList = res.data);
  },
  methods:{
    selectList() { // 搜索姓名
      const {selectName} = this;
      this.params.book_name = selectName;
      this.getData(this.params);
    },
    addBook(refName) { // 添加作者
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        const {cat_id, author_id} = this.form;
        const body = this.form;
        body.cat_id = Number(cat_id);
        body.author_id = Number(author_id);
        body.author_name = this.$refs.authorName.selectedLabel;
        addBookItem(body).then(() => {
          this.showDialog = false;
          this.$message.success('添加成功！');
          this.initParams();
          this.getData(this.params);
        });
      });
    },
    queryItem(id) { // 查询单条数据
      this.checkItem = true;
      this.checkData = {};
      queryBookItem(id).then(res => this.checkData = res.data);
    },
    editItem(refName) { // 修改单条数据
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        const {form, form: {id}} = this;
        const body = form;
        body.author_name = this.$refs.authorName.selectedLabel;
        const fields = Object.keys(body);
        const fields_1 = this.$removeItem(fields, 'create_time');
        const fields_2 = this.$removeItem(fields_1, 'update_time');
        body.fields = fields_2;
        editBookItem(id, body).then(() => {
          this.showDialog = false;
          this.$message.success('修改成功！');
          this.getData(this.params);
        });
      });
    },
    deleteItem(id) { // 删除单挑数据
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteBookItem(id).then(() => {
          this.$message.success('删除成功!');
          this.getData(this.params);
        });
      }).catch(() => this.$message.info('已取消删除'));
    },
    openDialog(item) { // 打开弹窗
      this.form = item;
      this.showDialog = true;
      this.isAddFlag = false;
    },
    closeDialog(flag, refName) { // 关闭弹窗
      if(flag) return this.showDialog = false;
      this.$refs[refName].resetFields();
      this.showDialog = false;
    },
    getSrcList(src) { // 查看图片
      this.srcList = [];
      this.srcList.push(src);
    },
    handleAvatarSuccess(res, file) { // 上传图片
      this.form.cover_pic = res.data.url;
    },
    beforeAvatarUpload(file) { // 上传图片
      const isImg = file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImg) this.$message.error('上传头像图片只能是 JPG/gif/jpeg/png 格式!');
      if (!isLt2M) this.$message.error('上传头像图片大小不能超过 2MB!');
      return isImg && isLt2M;
    },
    handleCurrentChange(val) { // 获取页数
      this.params.page = val;
      this.getData(this.params);
    },
    handleSizeChange(val) { // 获取每页条数
      this.params.page_size = val;
      this.getData(this.params);
    },
    getData(params) {
      this.loading = true;
      const {page_size} = this.params;
      getBookList(params).then(res => {
        this.loading = false;
        const {data, count} = res.data;
        this.total = count;

        if(count > 0 && data.length == 0) {
          const page = count / page_size;
          this.params.page = page;
          getBookList(this.params).then(result => this.tableData = result.data.data);
        }else {
          this.tableData = data;
        }
      });
    },
    initParams() { // 初始化数据
      this.form = {
        cover_pic: '',
        author_id: '',
        author_name: '',
        book_name: '',
        cat_id: '',
        desc: '',
        status: 0
      }
    }
  },
}
</script>

<style lang='less' scoped>
</style>